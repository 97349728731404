import React from "react";
import styled from "styled-components";

const TextLink = ({ children, href }) => {
  return <Frame href={href}>{children}</Frame>;
};

const Frame = styled.a`
  color: #988439;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  &:hover {
    border-bottom-color: transparent;
  }

  &:visited {
    border-bottom-style: dashed;
  }
`;

export default TextLink;
