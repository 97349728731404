import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Section from "../components/Section";
import Nav from "../components/Nav";
import Article from "../components/Article";
import H1 from "../components/H1";
import H2 from "../components/H2";
import Paragraph from "../components/Paragraph";
import TextLink from "../components/TextLink";

const CreditsPage = () => (
  <Layout>
    <SEO title="Credits" />
    <Nav />
    <Section>
      <Article>
        <H1>Credits</H1>
        <Paragraph isLarge>
          This site was made possible through the talents and contributions of numerous individuals
          and institutions.
        </Paragraph>
        <Paragraph>
          Thanks to Ann Morris of{" "}
          <TextLink href="https://millayhouserockland.org/">Millay House Rockland</TextLink> for
          contributing extensively to the texts on the history of Oak Hall.
        </Paragraph>
        <Paragraph>
          Thanks to the{" "}
          <TextLink href="https://penobscotmarinemuseum.org/">Penobscot Marine Museum</TextLink> for
          their conributions from their collection of historical photographs of the Oak Hall Estate.
        </Paragraph>
        <Paragraph>
          <strong>Design</strong> and estate photograpy by{" "}
          <TextLink href="http://fernando.is/">Fernando Maclen</TextLink>.
        </Paragraph>
      </Article>
    </Section>
    <Section>
      <Article>
        <H2>Estate Team</H2>
        <Paragraph>
          <strong>Architect (1912)</strong> <br />
          Benjamin H. Marshall (Marshall and Fox)
        </Paragraph>
        <Paragraph>
          <strong>Landscape Architect (1912)</strong> <br />
          Warren H. Manning
        </Paragraph>
        <Paragraph>
          <strong>Head Gardener</strong> <br />
          Emma Brown
        </Paragraph>
        <Paragraph>
          <strong>Landscape Design</strong> <br />
          Asa Peats
        </Paragraph>
        <Paragraph>
          <strong>Grounds</strong> <br />
          Drexel Bailey
        </Paragraph>
        <Paragraph>
          <strong>Master Engineer</strong> <br />
          Matt Freeman
        </Paragraph>
      </Article>
    </Section>
  </Layout>
);

export default CreditsPage;
