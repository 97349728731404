import React from "react";
import styled from "styled-components";

import TitleDivider from "../components/TitleDivider";

const H2 = ({ children }) => {
  return (
    <Frame>
      {children}
      <TitleDivider />
    </Frame>
  );
};

const Frame = styled.h2`
  display: grid;
  grid-gap: 1rem;
  font-size: 1.75rem;
  font-weight: 400;
  letter-spacing: -0.05em;
  line-height: 1.15em;
`;

export default H2;
